<template>
  <div class="login-wrap">
    <div class="login-body">
      <div class="cent">
        <img src="../assets/images/xg/LOGO.jpg" class="xg" />
       <!-- <p style="margin-top: 30px;">全民百惠</p> -->
      </div>
      <div class="ttabs">
            <van-form @submit="submit" style="margin-top: 20px;">
            <!--  <van-field v-model="mobile" name="mobile" placeholder="请输入手机号" clearable style="margin-bottom: 20px;" />
              <van-field v-model="password" :type="passwordShow ? 'text' : 'password'" name="password" placeholder="请输入密码"
                :right-icon="passwordShow ? 'eye-o' : 'closed-eye'" @click-right-icon="cheng()">
              </van-field> -->
              <div class="login-wrap-ft" style="text-align: center; margin-top: 40px">
              <van-button :loading="loading" type="warning" loading-text="链接钱包..." native-type="submit"
                  block>链接钱包</van-button>
                  <!-- <van-button  type="warning" @click="dappLogin"
                    block>登录</van-button> -->
              </div>
            </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getWebConfig } from "@/utils/api";
import Regi from "./register.vue";
export default {
  name: "Login",
  data() {
    return {
      mobile: "",
      password: "",
      dapp_address:"",
      loading: false,
      config: {},
      active: 'a',
      passwordShow: false,
    };
  },
  created() {
    console.log(this.$route);
    this.getConfig();
    localStorage.setItem("notice", "open");
  },
  methods: {
    dappLogin(){
      if (window.ethereum) {
              window.ethereum.enable().then((res) => {
                alert("当前钱包地址:" + res[0]);
              });
            } else {
              alert("请安装MetaMask钱包");
            }

    },
    goLink() {
      window.location.href = 'http://www.quanmingbaihui.com:301/qmbh.apk'
    },
    getConfig() {
      getWebConfig().then((res) => {
        this.config = res.data;
      });
    },
    download() {
      window.location.href = this.config.appUrl;
    },
    submit(value) {
      // if (value.mobile === "") {
      //   this.$toast({
      //     message: "请输入手机号",
      //     icon: 'fail',
      //     className: 'noticeWidth',
      //   });
      //   return;
      // }
      // if (value.password === "") {
      //   this.$toast({
      //     message: "请输入密码",
      //     icon: 'fail',
      //     className: 'noticeWidth',
      //   });
      //   return;
      // }

      if (window.ethereum) {
              window.ethereum.enable().then((res) => {
                // alert("当前钱包地址:" + res[0]);
                this.dapp_address = res[0];
              });
            } else {
              alert("请安装MetaMask钱包");
            }


      this.loading = true;
      const param = {
        // mobile: this.mobile,
        // password: this.password,
        dapp_address :this.dapp_address
      };
      this.login(param);
    },
    // 登录
    async login(param) {
      await this.$store
        .dispatch("user/login", param)
        .then(() => {
          this.loading = false;
          this.$store.dispatch("user/info");
          this.$toast.success({
            message: "登录成功",
            icon: 'success',
            className: 'noticeWidth',
            duration: 1500,
            onClose: () => {
              this.$router.push("/home");
              localStorage.setItem("hometype", "login");
            },
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    forget() {
      this.$router.push("/forget");
    },
    //密码显示与隐藏
    cheng() {
      if (this.passwordShow == false) {
        this.passwordShow = true
      } else {
        this.passwordShow = false
      }
    }
  },
  components: {
    Regi
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/css/font-face.css';
.login-wrap {
  font-family: pingfangs;
  background-size: 100% 100%;
  height: 100vh;
  position: relative;

  .login-body {
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    top: 50px;

    .cent {
      text-align: center;
      margin-bottom: 100px;
    }

    .xg {
      width: 400px;
    }
  }

  .van-form {
    .van-field {
      width: 690px;
      height: 96px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 1);
      // line-height: 60px;
    }

    .login-wrap-ft {
      .van-button {
        width: 690px;
        height: 120px;
        border-radius: 100px;
        background: rgba(68, 159, 46, 1);
        border: none;
        font-size: 36px;
      }
    }
  }

  .down {
    font-size: 20px;
  }

  .van-cell {
    margin-bottom: 20px;
    border-radius: 5px;
  }
}
</style>
<style lang="scss">
.noticeWidth {
  width: 30% !important;
  font-size: 32px !important;
}
.ttabs {
  .van-tabs__nav--card {
    border: none !important;
    border-radius: 30px;
    height: 60px;
    margin: 8px;
    background-color: rgba(249, 249, 249, 1) !important;
  }

  .van-tabs__wrap {
    border: 1px rgba(128, 128, 128, 0.1) solid !important;
    height: 80px !important;
    border-radius: 40px !important;
    background-color: rgba(249, 249, 249, 1) !important;
  }

  .van-tab--card {
    border-right: none;
    background-color: rgba(249, 249, 249, 1);
    border-radius: 40px !important;
    color: black;
  }

  .van-tab--active {
    background-color: white !important;
    color: black !important;
    border: none !important;
    border-radius: 30px !important;
    font-weight: bold;
  }

  .van-tab__text--ellipsis {
    overflow: visible !important;
    font-size: 30px !important;
  }

  .van-field__body {
    width: 100% !important;
  }

  .van-field__value {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    margin-left: 5% !important;
  }

  .van-icon-closed-eye {
    font-size: 38px  !important;
  }

  .van-icon-eye-o {
    font-size: 38px !important;
  }
  .van-field__control{
    height: 1rem !important;
  }
}
</style>
