<template>
  <!-- <div class="login-wrap">
    <div class="login-body"> -->
  <!-- <div>
        <img src="../assets/images/xg/home.png" class="xg" />
      </div> -->
  <van-form @submit="onSubmit" style="margin-top: 20px;" class="reist">
    <van-field v-model="mobile" name="mobile" maxlength="11" label-width="4rem" placeholder="请输入您的手机号码"
      style="margin-bottom: 20px;" />
   <!--  <div style="position:relative;">
          <van-field
            v-model="smsCodeNum"
            label-width="4rem"
            placeholder="请输入验证码"
            style=""
          />
          <img :src="captchaData.captcha" style="width:110px;position:absolute;right:0;z-index:4;bottom:0;cursor:pointer;" @click="getCaptcha()" />
        </div> -->
    <van-field v-model="password" :type="passwordShow ? 'text' : 'password'"
      :right-icon="passwordShow ? 'eye-o' : 'closed-eye'" @click-right-icon="cheng()" name="password" label-width="4rem"
      placeholder="请设置您的登录密码(最少6位)" style="margin-bottom: 20px;" />
    <van-field v-model="payPassword" name="payPassword" maxlength="6" label-width="4rem" placeholder="请设置您的资金密码(最少6位)"
      style="margin-bottom: 20px;" :type="passwordShowtwo ? 'text' : 'password'"
      :right-icon="passwordShowtwo ? 'eye-o' : 'closed-eye'" @click-right-icon="chengs()" />
    <van-field v-model="smsCodeNum" center clearable placeholder="请输入短信验证码">
      <template #button>
        <van-button size="big" @click="getSmsCode" type="primary" class="btnss">
          {{ smsCodeText }}
        </van-button>
      </template>
    </van-field>
    <van-field v-model="code" name="code" label-width="4rem" placeholder="请输入邀请码（选填）" style="margin-bottom: 20px;" />
    <van-radio-group v-model="checked" style="margin-bottom: 20px;">
      <van-radio name="1" checked-color="rgba(68, 159, 46, 1)" style="font-size: 16px;">我已阅读并同意<span
          style="color: red;">《隐私政策》</span></van-radio>
    </van-radio-group>
    <van-button block type="warning" native-type="submit" style="margin-bottom: 20px;">
      完成
    </van-button>
  </van-form>
</template>

<script>
import { smsCode, getCaptcha, bindVerify } from "@/utils/api.js";
export default {
  name: "register",
  data() {
    return {
      mobile: "", //手机号
      password: "", //密码
      payPassword: "", //支付密码
      code: "", //邀请码
      open_id: 123456,
      smsCodeNum: "",
      smsCodeTime: 120,
      smsCodeText: "获取验证码",
      smsCodeInterval: null,
      captchaData: {},
      checked: '1',
      passwordShow: false,
      passwordShowtwo: false,
      // loading: false,
    };
  },
  created() {
    this.getCaptcha();
    this.code = this.$route.query.yqm;
  },
  methods: {
    getCaptcha() {
      getCaptcha().then((res) => {
        this.captchaData = res.data;
      });
    },
    onSubmit() {
      if (this.mobile === "") {
        this.$toast({
          message: "请输入手机号",
          icon: 'fail',
          className: 'noticeWidth',
        });
        return;
      }
      if (this.mobile.length < 11) {
        this.$toast({
          message: "请输入正确手机号",
          icon: 'fail',
          className: 'noticeWidths',
        });
        return;
      }
      if (this.password === "") {
        this.$toast({
          message: "请输入登录密码",
          icon: 'fail',
          className: 'noticeWidth',
        });
        return;
      }
      if (this.password < 6) {
        this.$toast({
          message: "密码至少6位数",
          icon: 'fail',
          className: 'noticeWidth',
        });
        return;
      }
      if (this.payPassword === "") {
        this.$toast({
          message: "请再次输入密码",
          icon: 'fail',
          className: 'noticeWidth',
        });
        return;
      }
      if (this.payPassword.length !== 6) {
        this.$toast({
          message: "密码最少6位数字",
          icon: 'fail',
          className: 'noticeWidth',
        });
        return;
      }
      // if (this.password !== this.payPassword) {
      //   this.$toast({
      //     message: "2次密码不同",
      //     icon: 'fail',
      //     className: 'noticeWidth',
      //   });
      //   return;
      // }
      // if (!this.smsCodeNum || this.smsCodeNum.length !== 4) {
      //   this.$toast({
      //     message: "请输入验证码",
      //     icon: 'fail',
      //     className: 'noticeWidth',
      //   })
      //   return
      // }
      // if (this.code === undefined) {
      //   this.$toast({
      //     message: "请输入邀请码",
      //     icon: 'fail',
      //     className: 'noticeWidth',
      //   });
      //   return;
      // }

      // this.loading = true;
      const param = {
        mobile: this.mobile,
        password: this.password,
        payPassword: this.payPassword,
        code: this.code,
        open_id: this.open_id,
        mscode: this.smsCodeNum,
        uuid: this.captchaData.uuid,
      };
      this.confirm(param);
    },
    async confirm(param) {
      this.$toast.loading({
        duration: 0,
        message: "提交中...",
        forbidClick: true,
        className: 'noticeWidth',
      });
      await this.$store.dispatch("user/register", param).then(() => {
        // this.loading = false;
        this.$store.dispatch("user/info");
        // this.$toast.clear();
        // this.$toast.success({ message: res.message, className: 'noticeWidths', });
        // setTimeout(() => {
        //   this.login();
        // }, 1500);
        this.$toast.success({
          message: "注册成功",
          icon: 'success',
          className: 'noticeWidth',
          duration: 1500,
          onClose: () => {
            this.$router.push("/login");
            localStorage.setItem("hometype", "login");
          },
        });
      }).catch(() => {
        // this.loading = false;
        this.$toast({
          message: '注册失败',
          icon: 'fail',
          className: 'noticeWidths',
        });
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    login() {
      localStorage.setItem("hometype", "login");
      this.$router.push("/home");
    },
    getSmsCode() {
      if (this.smsCodeInterval !== null) {
        return;
      }
      if (!this.mobile || this.mobile.length !== 11) {
        this.$toast({
          message: "请输入正确手机号",
          icon: 'fail',
          className: 'noticeWidths',
        });
        return;
      }
      bindVerify({ mobile: this.mobile }).then((res) => {
        if (res.code == 200) {
          this.$toast({
            message: "短信发送成功！请注意查收。",
            icon: 'success',
            className: 'noticeWidtht',
          });
          this.smsCodeText = this.smsCodeTime + "s 后重新获取";
          this.smsCodeInterval = setInterval(() => {
            this.smsCodeTime -= 1;
            this.smsCodeText = this.smsCodeTime + "s 后重新获取";
            if (this.smsCodeTime <= 0) {
              this.smsCodeText = "发送验证码";
              clearInterval(this.smsCodeInterval);
              console.log(this.smsCodeInterval);
            }
          }, 1000);
        } else {
          this.$toast({
            message: res.message,
            icon: 'fail',
            className: 'noticeWidtht',
          });
        }
      });
    },
    //密码显示与隐藏
    cheng() {
      if (this.passwordShow == false) {
        this.passwordShow = true
      } else {
        this.passwordShow = false
      }
    },
    chengs() {
      if (this.passwordShowtwo == false) {
        this.passwordShowtwo = true
      } else {
        this.passwordShowtwo = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.login-wrap {
  background-size: 100% 100%;
  height: 100vh;

  .login-body {
    position: absolute;
    transform: translate(-50%);
    top: 20px;
    left: 50%;

    .xg {
      width: 690px;
    }
  }

  .van-form {
    .van-field {
      width: 690px;
      height: 96px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 1);
      line-height: 60px;
    }

    .van-button {
      width: 690px;
      height: 120px;
      border-radius: 100px;
      background: rgba(68, 159, 46, 1);
      border: none;
      font-size: 36px;
    }

    .btnss {
      width: 2rem;
      height: 60px;
      font-size: 24px;
    }
  }

  .down {
    font-size: 20px;
  }

  .van-cell {
    margin-bottom: 20px;
    border-radius: 5px;
  }
}
</style>
<style lang="scss">
.noticeWidth {
  width: 30% !important;
  font-size: 32px !important;
}

.noticeWidths {
  width: 40% !important;
  font-size: 32px !important;
}

.noticeWidtht {
  width: 60% !important;
  font-size: 32px !important;
}

.van-tab--card {
  border-right: none;
}

.reist {
  .van-tabs__nav--card {
    border: none !important;
    border-radius: 30px;
    height: 60px;
    margin: 8px;
    background-color: rgba(249, 249, 249, 1) !important;
  }

  .van-tabs__wrap {
    border: 1px rgba(128, 128, 128, 0.1) solid !important;
    height: 80px !important;
    border-radius: 40px !important;
    background-color: rgba(249, 249, 249, 1) !important;
  }

  .van-tab--card {
    background-color: rgba(249, 249, 249, 1);
    border-radius: 40px !important;
    color: black !important;
  }

  .van-tab--active {
    background-color: white !important;
    color: black !important;
    border: none !important;
    border-radius: 30px !important;
    font-weight: bold;
  }

  .van-tab__text--ellipsis {
    overflow: visible !important;
    font-size: 30px;
  }

  .van-field__body {
    width: 100% !important;
  }

  .van-field__value {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    margin-left: 5% !important;
  }

  .van-radio__label {
    line-height: normal !important;
  }

  .van-radio__icon {
    font-size: 100% !important;
  }

  .van-icon-closed-eye {
    font-size: 38px !important;
  }

  .van-icon-eye-o {
    font-size: 38px !important;
  }

  .van-field__control {
    height: 40px !important;
  }
}
</style>
